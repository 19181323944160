import Appsignal from "@appsignal/javascript";
import { CREDENTIALS } from "@constants";

import { plugin as breadcrumbsPlugin } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as networkBreadcrumbsPlugin } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator";
import toast from "@components/Toast";

const appsignal = new Appsignal({
  key: CREDENTIALS.APPSIGNAL_FRONTEND_API_KEY,
  ignoreErrors: [/Can't verify CSRF token authenticity\./, /Illegal invocation/],
});

appsignal.use(breadcrumbsPlugin);
appsignal.use(networkBreadcrumbsPlugin);
appsignal.use(windowEventsPlugin);
appsignal.use(pathDecoratorPlugin);

appsignal.addDecorator((span) => {
  return span.setTags({ currentUserId: gon.current_user_id, url: window.location.href });
});

if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  appsignal.addOverride((span: any) => {
    console.error("Appsignal error: ", span._data);
    toast.error("Appsignal error reported. Printed details to console.");
    return span;
  });
}

export default appsignal;
