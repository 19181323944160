import styled from "styled-components";
import Thumbnail from "../Thumbnail";
import { FONTS, STYLES } from "@constants";

interface Props {
  users: {
    id: number;
    name: string;
    image: { medium: { url: string | null } };
    location: string;
  }[];
}

const MultiUserPreview = ({ users }: Props) => {
  return (
    <Container>
      <Thumbnail users={users} size="md" />
      <div>
        <NameContainer>
          {users.map((user, index) => {
            return (
              <StyledName key={user.id}>
                {user.name}
                {/* 4 total can be displayed */}
                {index !== users.length - 1 && index < 3 && ", "}
              </StyledName>
            );
          })}
        </NameContainer>
        <UserAssociation>{users[0].location}</UserAssociation>
      </div>
    </Container>
  );
};

export default MultiUserPreview;

const Container = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-gap: 0 24px;
`;
const StyledName = styled.span`
  ${FONTS.SEMIBOLD_2};
  max-width: 210px;
  white-space: pre;
  display: inline-block;
`;
const UserAssociation = styled.div`
  ${FONTS.REGULAR_3};
  ${STYLES.ONE_LINE};
`;
const NameContainer = styled.div`
  max-width: 241px;
  ${STYLES.ONE_LINE};
  line-height: normal;
  -webkit-line-clamp: 2;
`;
