import beams from "@images/headers/beams.png";
import drops from "@images/headers/drops.png";
import hoop from "@images/headers/hoop.png";
import lines from "@images/headers/lines.png";
import splash from "@images/headers/splash.png";
import stars from "@images/headers/stars.png";

const HEADERS = {
  0: beams,
  1: drops,
  2: hoop,
  3: lines,
  4: splash,
  5: stars,
};

export default HEADERS;
