import styled from "styled-components";
import { COLORS } from "@constants";
import { Icon } from "@components/library";

interface Props {
  status:
    | "active"
    | "inactive"
    | "coming soon"
    | "trial"
    | "complete"
    | "open call"
    | "open call paused"
    | "open call complete";
}

const RequestStatusIcon = ({ status }: Props) => {
  switch (status) {
    case "coming soon": {
      return (
        <Container color={COLORS.ORANGE_200}>
          <Icon name="RFP" size="sm" color={COLORS.ORANGE} />
        </Container>
      );
    }
    case "trial": {
      return (
        <Container color={COLORS.GREEN_200}>
          <Icon name="Quota-Based RFP" size="sm" color={COLORS.GREEN_800} />
        </Container>
      );
    }
    case "active": {
      return (
        <Container color={COLORS.GREEN_200}>
          <Icon name="RFP" size="sm" color={COLORS.GREEN_800} />
        </Container>
      );
    }
    case "inactive": {
      return (
        <Container color={COLORS.NEUTRAL_250}>
          <Icon name="RFP" size="sm" />
        </Container>
      );
    }
    case "complete": {
      return (
        <Container color={COLORS.NEUTRAL_250}>
          <Icon name="RFP Complete" size="sm" />
        </Container>
      );
    }
    case "open call": {
      return (
        <Container color={COLORS.GREEN_200}>
          <Icon name="Open Call" size="sm" color={COLORS.GREEN_800} />
        </Container>
      );
    }
    case "open call paused": {
      return (
        <Container color={COLORS.NEUTRAL_250}>
          <Icon name="Open Call Paused" size="sm" />
        </Container>
      );
    }
    case "open call complete": {
      return (
        <Container color={COLORS.NEUTRAL_250}>
          <Icon name="Open Call Complete" size="sm" />
        </Container>
      );
    }
    default: {
      return null;
    }
  }
};

export default RequestStatusIcon;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  min-width: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
