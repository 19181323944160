import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Link } from "react-router-dom";

interface Props {
  to: string | { pathname: string };
  children: any;
  color?: string;
  font?: string;
  underline?: boolean;
  newTab?: boolean;
}

const TextLink = ({
  to,
  children,
  color = COLORS.BLACK,
  font = FONTS.MEDIUM_2,
  underline = false,
  newTab = false,
}: Props) => {
  return (
    <StyledLink
      to={to}
      color={color}
      font={font}
      $underline={underline}
      target={newTab ? "_blank" : ""}
      rel={newTab ? "noreferrer" : ""}
    >
      {children}
    </StyledLink>
  );
};

export default TextLink;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ color }) => color};
  ${({ font }) => font};
  ${({ $underline }) => $underline && `text-decoration: underline;`};
  &:hover,
  &:focus {
    color: ${({ color }) => color};
  }
`;
