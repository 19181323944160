import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessengerState } from "@redux/actions/messengerActions";
import { MessengerData } from "@redux/reducers/messengerReducer";
import styled from "styled-components";
import { COLORS, FONTS, STYLES } from "@constants";
import { Link } from "react-router-dom";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import { TruncatedText, TextButton } from "@components/library";
import { PremiumTag, SuperAdminTag } from "../Tags/users";

interface Props {
  size?: "sm" | "md" | "lg" | "xl";
  user?: {
    name: string;
    image: { medium: { url: string | null } };
    profile_id?: string;
    title?: string;
    location?: string;
    profile_info: {
      title: string;
      location?: string;
      is_premium?: boolean;
      is_super_admin?: boolean;
    };
  };
  disableLink?: boolean;
  withMessageButton?: boolean;
  messengerData?: MessengerData;
  userRole?: 0 | 1 | 2;
  hidden?: boolean;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  showSponsorRoles?: boolean;
}

const UserPreview = ({
  size = "sm",
  user,
  disableLink = false,
  withMessageButton = false,
  messengerData,
  userRole = 0,
  hidden = false,
  tooltipPosition = "top",
  showSponsorRoles = false,
}: Props) => {
  const [isMessageButtonHovered, setIsMessageButtonHovered] = useState(false);

  const dispatch = useDispatch();

  const disabled = disableLink || userRole !== 0 || hidden;

  const handleMessageClick = (e) => {
    e.preventDefault();

    if (messengerData) {
      dispatch(setMessengerState(messengerData));
    }
  };

  let association = (
    <UserAssociation size={size}>
      {userRole === 1 && hidden ? "Reviewer" : user?.profile_info?.title ?? user?.title}
    </UserAssociation>
  );

  if (userRole === 0) {
    association = (
      <TruncatedText
        text={
          hidden
            ? "Reviewer"
            : `${user?.profile_info?.title ?? user?.title ?? "Researcher"}, ${
                user?.profile_info?.location ?? user?.location
              }`
        }
        font={size === "sm" || size === "md" ? FONTS.REGULAR_3 : FONTS.REGULAR_2}
        tooltipPosition={tooltipPosition}
      />
    );
  }

  return (
    <Container data-testid="user-preview">
      <StyledLink
        as={disabled ? "div" : Link}
        to={disabled ? null : `/profile/${user?.profile_id}`}
        disabled={disabled}
        target="_blank"
        rel={"noreferrer"}
        size={size}
        isMessageButtonHovered={isMessageButtonHovered}
      >
        <ProfilePicture size={size} user={user} hidden={hidden} />
        <Text>
          <TopRow>
            <Name size={size} className="name" data-testid="name">
              {hidden ? "Team Member" : user?.name}
            </Name>
            {withMessageButton && messengerData ? (
              <TextButton
                text="Message"
                iconName="Message"
                iconPosition="left"
                margin="4px 0 0 12px"
                size="sm"
                onMouseEnter={() => setIsMessageButtonHovered(true)}
                onMouseLeave={() => setIsMessageButtonHovered(false)}
                onClick={(e) => handleMessageClick(e)}
                data-testid="user-preview-message-button"
              />
            ) : (
              showSponsorRoles && (
                <>
                  {user?.profile_info?.is_super_admin && (
                    <SuperAdminTagContainer>
                      <SuperAdminTag />
                    </SuperAdminTagContainer>
                  )}
                  {user?.profile_info?.is_premium && (
                    <PremiumTagContainer>
                      <PremiumTag />
                    </PremiumTagContainer>
                  )}
                </>
              )
            )}
          </TopRow>
          {association}
        </Text>
      </StyledLink>
    </Container>
  );
};

export default UserPreview;

const Container = styled.div`
  width: auto;
  line-height: normal;
`;
const StyledLink = styled(Link)`
  display: inline-grid;
  grid-template-columns: ${({ size }) => {
    switch (size) {
      case "sm": {
        return "36px 1fr";
      }
      case "md": {
        return "50px 1fr";
      }
      case "lg": {
        return "68px 1fr";
      }
      case "xl": {
        return "96px 1fr";
      }
    }
  }};
  grid-gap: ${({ size }) => {
    switch (size) {
      case "sm":
      case "md":
        return "0 8px";
      case "lg":
      case "xl":
        return "0 16px";
    }
  }};
  align-items: center;
  width: auto;
  color: ${COLORS.BLACK};
  &:hover {
    text-decoration: none;
    color: ${COLORS.BLACK};
    & .name {
      text-decoration: ${({ disabled, isMessageButtonHovered }) =>
        disabled || isMessageButtonHovered ? "none" : "underline"};
    }
  }
  &:focus {
    text-decoration: none;
    color: ${COLORS.BLACK};
  }
`;
const Text = styled.div`
  min-width: 0;
`;
const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const SuperAdminTagContainer = styled.div`
  margin-bottom: -4px;
  pointer-events: none;
`;
const PremiumTagContainer = styled.div`
  margin-bottom: -4px;
  pointer-events: none;
`;
const Name = styled.div`
  ${STYLES.ONE_LINE}
  ${({ size }) => {
    switch (size) {
      case "sm":
      case "md": {
        return FONTS.TAG_SEMIBOLD_1;
      }
      case "lg":
      case "xl": {
        return FONTS.HEADING_5_SEMIBOLD;
      }
    }
  }};
  margin-top: 4px;
`;
const UserAssociation = styled.div`
  ${STYLES.TWO_LINES}
  ${({ size }) => {
    switch (size) {
      case "sm":
      case "md": {
        return FONTS.REGULAR_3;
      }
      case "lg":
      case "xl": {
        return FONTS.REGULAR_2;
      }
    }
  }};
`;
