// Note: Make sure to add new flags to `app/services/flipper_gates.rb` too!
const FEATURE_FLAGS = [
  "NOTIFICATION_CAMPAIGNS_SCHEDULING",
  "NOTIFICATION_RFP_CAMPAIGNS",
  "NOTIFICATION_RFP_ENGAGED_VIEWER_SEQUENCE",
  "NOTIFICATION_RFP_LAUNCH_DIRECT_SEQUENCE",
  "HUBSPOT_FORM_SUBMISSION_SYNC",
  "HUBSPOT_SHADOW_PROFILE_LIST_SYNC",
  "HUBSPOT_TAXONOMY_SYNC",
  "UNCLAIMED_COMPANY_PAGE",
  "REQUEST_FOR_STARTUPS",
  "KEYWORD_SUGGESTIONS",
  "PROPOSAL_TECHNOLOGY_READINESS_LEVEL",
  "PROPOSAL_REVIEW_SYSTEM_V2",
  "HOW_DID_YOU_HEAR_ABOUT_US",
  "SIGN_OUT_ON_ONBOARDING",
  "SESSION_TIMEOUT",
] as const;

// Export object of { [name as symbol]: boolean }
export default FEATURE_FLAGS.reduce(
  (obj, flag) => ({ ...obj, [flag]: gon.global?.feature_flags?.includes(flag) }),
  {}
) as { [F in typeof FEATURE_FLAGS[number]]: boolean };
