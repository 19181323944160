import { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { COLORS, FONTS } from "@constants";
import { InfoTag } from "@components/library";

interface Props {
  activeTab: string;
  setActiveTab: (newTab: string) => void;
  tabs: {
    key: string;
    label: string | ReactNode;
    count?: number;
    onClick?: () => void;
    "data-testid"?: string;
  }[];
  variant?: "normal" | "attention";
  activeColor?: string;
  font?: string;
  tabDistance?: string;
  margin?: string | 0;
}

const Tabs = ({
  activeTab,
  setActiveTab,
  tabs,
  variant = "normal",
  activeColor,
  font = FONTS.SEMIBOLD_2,
  tabDistance = "24px",
  margin,
}: Props) => {
  const [hoveredTab, setHoveredTab] = useState<string | null>();

  return (
    <Container margin={margin} tabDistance={tabDistance}>
      {tabs.map((tab) => {
        const isActive = activeTab === tab.key;
        return (
          <Tab
            type="button"
            key={tab.key}
            isActive={isActive}
            onClick={() => {
              setActiveTab(tab.key);
              if (tab.onClick) tab.onClick();
            }}
            onMouseEnter={() => setHoveredTab(tab.key)}
            onMouseLeave={() => setHoveredTab(null)}
            variant={variant}
            activeColor={activeColor}
            font={font}
            data-testid={tab["data-testid"]}
          >
            {tab.label}
            {typeof tab.count === "number" && (
              <InfoTag
                backgroundColor={(() => {
                  if (isActive) {
                    return activeColor ?? (variant === "normal" ? COLORS.BLACK : COLORS.ORANGE_900);
                  } else if (hoveredTab === tab.key) {
                    return variant === "normal" ? COLORS.NEUTRAL_500 : COLORS.ORANGE_900;
                  }
                  return variant === "normal" ? COLORS.NEUTRAL_400 : COLORS.ORANGE;
                })()}
              >
                {tab.count}
              </InfoTag>
            )}
          </Tab>
        );
      })}
    </Container>
  );
};

export default Tabs;

const Container = styled.div`
  display: flex;
  gap: ${({ tabDistance }) => tabDistance};
  ${({ margin }) => margin && `margin: ${margin}`};
`;
const Tab = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 5px;
  border: none;
  border-bottom: ${({ isActive, variant, activeColor }) =>
    `2px solid ${(() => {
      if (isActive) {
        if (activeColor) return activeColor;
        if (variant === "normal") return COLORS.BLACK;
        if (variant === "attention") return COLORS.ORANGE_900;
      } else {
        return "transparent";
      }
    })()}`};
  background-color: transparent;
  ${({ font }) => font};
  color: ${({ isActive, variant, activeColor }) => {
    if (isActive) {
      if (activeColor) return activeColor;
      if (variant === "normal") return COLORS.BLACK;
      if (variant === "attention") return COLORS.ORANGE_900;
    }
    return variant === "normal" ? COLORS.NEUTRAL_400 : COLORS.ORANGE;
  }};
  ${({ isActive, variant }) =>
    !isActive &&
    css`
      &:hover {
        color: ${variant === "normal" ? COLORS.NEUTRAL_500 : COLORS.ORANGE_900};
        border-bottom: 2px solid ${variant === "normal" ? COLORS.NEUTRAL_500 : COLORS.ORANGE_900};
      }
    `}
  &:active {
    outline: none;
  }
`;
