import styled from "styled-components";
import { COLORS, FONTS } from "@constants";

interface Props {
  children: number | string;
  marginLeft?: string;
  accent?: boolean;
  backgroundColor?: string;
}

const InfoTag = ({ children, marginLeft = "12px", accent = false, backgroundColor }: Props) => {
  return (
    <Container marginLeft={marginLeft} accent={accent} backgroundColor={backgroundColor}>
      {children}
    </Container>
  );
};

export default InfoTag;

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  min-width: 20px;
  width: auto;
  background: ${({ accent, backgroundColor }) =>
    backgroundColor || (accent && COLORS.BLUE_GRADIENT) || COLORS.NEUTRAL_400};
  color: ${COLORS.WHITE};
  border-radius: 100px;
  padding: ${({ accent }) => (accent ? "2px 8px" : "2px 6px")};
  margin-left: ${({ marginLeft }) => marginLeft};
  ${({ accent }) => (accent ? FONTS.TAG_ACCENT : FONTS.TAG_BOLD_2)};
`;
