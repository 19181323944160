import { COLORS } from "@constants";
import Tag from "../Tag";
import { PremiumTagTooltip } from "./PremiumTagTooltip";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";

export function PremiumTag() {
  const subscription = useSelector((state: RootState) => state.company.subscription);

  if (subscription?.plan === "rfp_based") return null;

  return (
    <PremiumTagTooltip>
      <Tag
        iconName="Star"
        content="Premium"
        size="xs"
        backgroundColor={COLORS.PINK_100}
        color={COLORS.PINK_800}
        data-testid="premium-tag"
      />
    </PremiumTagTooltip>
  );
}
