import { useState, useEffect, useRef, ChangeEvent } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";

interface Props {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  borderColor?: string;
}

const ChipCheckbox = ({
  label,
  checked,
  disabled,
  onChange,
  borderColor = COLORS.NEUTRAL_300,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const checkboxRef = useRef<HTMLInputElement>(null);
  const isFocusedRef = useRef(false);

  const onKeyDown = (e) => {
    if (e.code === "Enter" && isFocusedRef.current) {
      checkboxRef.current?.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <Label checked={checked} disabled={disabled} borderColor={borderColor} isFocused={isFocused}>
      {label}
      <Checkbox
        type="checkbox"
        ref={checkboxRef}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        onFocus={() => {
          isFocusedRef.current = true;
          setIsFocused(true);
        }}
        onBlur={() => {
          isFocusedRef.current = false;
          setIsFocused(false);
        }}
      />
      {checked && <Icon name="X" size="xs" color={COLORS.HALO_BLUE} margin="0 0 0 8px" />}
    </Label>
  );
};

export default ChipCheckbox;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 36px;
  ${FONTS.MEDIUM_3};
  color: ${COLORS.BLACK};
  padding: 0 14px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 100px;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &&& {
    ${({ checked, disabled, isFocused }) => {
      if (checked) {
        return `
          background-color: ${COLORS.BLUE_LIGHT_300};
          border-color: ${COLORS.HALO_BLUE};
          color: ${COLORS.HALO_BLUE};
          :hover {
            & > :last-child {
              opacity: 0.4;
            }
          }
        `;
      } else if (disabled) {
        return `
          background-color: ${COLORS.NEUTRAL_200};
          border-color: ${COLORS.NEUTRAL_400};
          opacity: 0.5;
        `;
      } else if (isFocused) {
        return `
          background-color: ${COLORS.NEUTRAL_100};
          border-color: ${COLORS.NEUTRAL_400};
        `;
      }
    }}
  }
  :hover {
    background-color: ${COLORS.NEUTRAL_100};
    border-color: ${COLORS.NEUTRAL_400};
  }
`;
const Checkbox = styled.input`
  display: none;
`;
