import { COLORS } from "@constants";

import styled from "styled-components";
import Icon from "../Icon";
import { PROFILE_PICTURE_SIZE_STYLES, ProfilePictureProps } from "./ProfilePicture";

interface Props {
  size: ProfilePictureProps["size"];
}

const InvitedUserProfilePicture = ({ size = "sm" }: Props) => {
  return (
    <Container>
      <Outline size={size}>
        <Icon name="Profile" size="sm" />
      </Outline>
    </Container>
  );
};

export default InvitedUserProfilePicture;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Outline = styled.div`
  border: 1px dashed ${COLORS.BLACK};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${PROFILE_PICTURE_SIZE_STYLES}
`;
