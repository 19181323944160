import { useState } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";
import DROPDOWN_STYLES, { MULTI_SELECT_TAG_STYLES } from "../Dropdowns/dropdownStyles";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import type { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { getValueFromOption } from "@utils/dropdownUtils";

interface Props {
  value: DropdownOption[];
  onChange: (...args: any[]) => any;
  placeholder: string;
  createOptionText?: string; // e.g. "Search proposals for"
  suggestedOptions?: DropdownOption[];
}

const CreateOptionLabel = (input: string, createOptionText?: string) => {
  return (
    <CreateLabel>
      <Icon name="Search" color={COLORS.HALO_BLUE} margin="0 10px 0 0" />
      {`${createOptionText ?? "Search for"} "${input}"`}
    </CreateLabel>
  );
};

const NoOptionsMessage = ({
  children,
  getValue,
  selectOption,
}: {
  children: DropdownOption[];
  getValue: () => DropdownOption[];
  selectOption: (newValue: DropdownOption) => void;
}) => {
  const values: string[] = getValue().map(getValueFromOption);
  const filteredChildren = children?.filter(({ value }: DropdownOption) => !values.includes(value));

  if (!filteredChildren?.length)
    return (
      <NoOptionsLabel>
        <Icon name="Search" color={COLORS.HALO_BLUE} margin="0 10px 0 0" />
        Search by title, content, keywords, researcher, or organization
      </NoOptionsLabel>
    );

  return (
    <div>
      <SuggestedOptionsHeader>Try searching for</SuggestedOptionsHeader>
      {children
        .filter(({ value }: DropdownOption) => !values.includes(value))
        .map(({ value, label }: DropdownOption) => (
          <SuggestedOptionLabel key={value} onClick={() => selectOption({ label, value })}>
            <Icon name="Search" color={COLORS.HALO_BLUE} margin="0 10px 0 0" />
            <SuggestedOptionText>{value}</SuggestedOptionText>
          </SuggestedOptionLabel>
        ))}
    </div>
  );
};

export const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      // From React-Select docs
      // eslint-disable-next-line
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <Icon name="X" size="xs" margin="0 8px 0 0" />
    </div>
  );
};

const MultiValueRemove = ({ ...props }) => {
  return (
    // From the React-Select docs
    /* eslint-disable-next-line */ //@ts-ignore
    <components.MultiValueRemove {...props}>
      <Icon name="Close" size="xs" margin="0 0 0 8px" />
    </components.MultiValueRemove>
  );
};

const SearchBar = ({ value, placeholder, onChange, createOptionText, suggestedOptions }: Props) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Container data-testid="search-bar">
      <IconContainer>
        <Icon name="Search" color={COLORS.NEUTRAL_400} margin="0 0 0 16px" />
      </IconContainer>
      <Dropdown
        classNamePrefix="Select"
        components={{ ClearIndicator, MultiValueRemove, NoOptionsMessage }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        formatCreateLabel={(input) => CreateOptionLabel(input, createOptionText)}
        noOptionsMessage={() => suggestedOptions}
        inputValue={inputValue}
        onInputChange={(e) => setInputValue(e)}
        isMulti
        openMenuOnFocus={true}
      />
    </Container>
  );
};

export default SearchBar;

const CreateLabel = styled.div`
  display: flex;
  align-items: center;
`;
const NoOptionsLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.NEUTRAL_500};
`;
const SuggestedOptionsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;
const SuggestedOptionLabel = styled.div`
  display: flex;
  align-items: center;
  ${({ isSelected }) => (isSelected ? FONTS.MEDIUM_2 : FONTS.REGULAR_2)};
  color: ${({ isSelected }) => (isSelected ? COLORS.HALO_BLUE : COLORS.BLACK)};
  margin: 0;
  padding: 10px 14px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.NEUTRAL_100};
  }
`;
const SuggestedOptionText = styled.span`
  overflow-wrap: break-word;
  white-space: normal;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const IconContainer = styled.div`
  position: absolute;
  z-index: 1;
`;
export const Dropdown = styled(CreatableSelect)`
  ${DROPDOWN_STYLES};
  ${MULTI_SELECT_TAG_STYLES};
  width: 100%;

  .Select__control {
    min-height: 54px;
  }
  .Select__value-container {
    padding-left: 42px;
    cursor: text;
  }
  .Select__dropdown-indicator {
    display: none;
  }
  .Select__indicators {
    height: 52px;
    &:hover {
      opacity: 0.5;
    }
  }
  .Select__menu {
    padding: 8px 12px;
  }
  .Select__option {
    color: ${COLORS.HALO_BLUE};
    ${FONTS.MEDIUM_2};
    padding-right: 16px;
  }
`;
