const PROPOSAL_STATUSES_SCIENTIST = {
  // Order
  0: "Awaiting review",
  3: "Advanced",
  4: "Finalist",
  5: "Awarded",
  2: "Declined",
};

export default PROPOSAL_STATUSES_SCIENTIST;
