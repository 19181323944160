import COLORS from "./colors";
// @ts-ignore
import PROPOSAL_STATUSES, { PROPOSAL_STATUS_TO_TAB } from "./proposalStatuses.js.erb";
// @ts-ignore
import CREDENTIALS from "./credentials.js.erb";
// @ts-ignore
import COMPANIES from "./companies.js.erb";
import WINDOW_DIMENSIONS from "./windowDimensions";
import PROPOSAL_STATUSES_SCIENTIST from "./proposalStatusesScientist";
import HUBSPOT from "./hubspot";
import HEADERS from "./company_headers";
import BLOCKED_EMAIL_DOMAINS from "./blockedEmailDomains";
import RFP_MAX_CHAR_COUNT from "./CharLimitConst";
import { TESTING4AG, CORTEVA } from "./requestForMaterials";
import FONTS, { JS_FONTS } from "./fonts";
import STYLES from "./styles";
import PROPOSAL_TYPES from "./proposalTypes";
import { PATENT_STATUS_OPTIONS } from "./materials";
import COUNTRY_OPTIONS from "./countryOptions";
import ENV from "./env";
import FEATURE_FLAGS from "./featureFlags";
import LINKS from "./links";

export const SUBSCRIPTION_END_WARNING_START = 60;
export const HALO_INTRO_LINK = "https://meetings.hubspot.com/hana-moss/halo-intro";

export {
  COLORS,
  PROPOSAL_STATUSES,
  PROPOSAL_STATUS_TO_TAB,
  PROPOSAL_STATUSES_SCIENTIST,
  HUBSPOT,
  WINDOW_DIMENSIONS,
  HEADERS,
  BLOCKED_EMAIL_DOMAINS,
  RFP_MAX_CHAR_COUNT,
  TESTING4AG,
  CORTEVA,
  FONTS,
  JS_FONTS,
  STYLES,
  PROPOSAL_TYPES,
  PATENT_STATUS_OPTIONS,
  COUNTRY_OPTIONS,
  ENV,
  CREDENTIALS,
  COMPANIES,
  FEATURE_FLAGS,
  LINKS,
};
