import { Dispatch, SetStateAction } from "react";
import { MessageAttachment } from "@tsTypes/schema";
import request from "./request";
import { MessageThreadsIndexResponse } from "@tsTypes/message_threads";
import {
  MessageAttachmentPartial,
  MessageThreadLookupResponse,
  MessageThreadsShowResponse,
} from "@tsTypes/index";

export type MessageAttachmentAsJson = MessageAttachment & {
  file_url: string;
  thumbnail_url: string;
  download_url: string;
};

export const getMessageThreads = async (): Promise<MessageThreadsIndexResponse> => {
  const response = await request.get("/message_threads");
  return response.data;
};

export const getMessages = async (messageThreadId: number): Promise<MessageThreadsShowResponse> => {
  const response = await request.get(`/message_threads/${messageThreadId}`);

  return response.data;
};

export const addMessageToThread = async ({
  messageThreadId,
  content,
  attachmentIds,
}: {
  messageThreadId: number;
  content: string;
  attachmentIds: number[];
}) => {
  const response = await request.post(`/message_threads/${messageThreadId}/add_message`, {
    message_thread_id: messageThreadId,
    content,
    attachment_ids: attachmentIds,
  });

  return response.data;
};

export const setDraftMessage = async ({
  proposalId,
  content,
  attachmentIds,
}: {
  proposalId: number;
  content: string;
  attachmentIds: number[];
}) => {
  const response = await request.post(`/draft_messages`, {
    proposal_id: proposalId,
    content,
    attachment_ids: attachmentIds,
  });

  return response.data;
};

export const getDraftMessage = async (
  proposalId: number
): Promise<
  | undefined
  | {
      id: number;
      content: number;
      message_attachments: MessageAttachmentPartial[];
    }
> => {
  try {
    const response = await request.get(`/draft_messages`, {
      params: {
        proposal_id: proposalId,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return undefined;
    }
    throw error;
  }
};

// Returns message_thread and messages or undefined if no message thread exists
export const getMessageByUserProposal = async ({
  userId,
  proposalId,
}): Promise<MessageThreadLookupResponse | undefined> => {
  try {
    const response = await request.get(`/message_threads/lookup`, {
      params: {
        user_id: userId,
        proposal_id: proposalId,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return undefined;
    }

    throw error;
  }
};

export const getMessageByRecipients = async ({
  initiatorUserId,
  recipientUserId,
}): Promise<MessageThreadLookupResponse | undefined> => {
  try {
    const response = await request.get("/message_threads/lookup", {
      params: {
        initiator_user_id: initiatorUserId,
        recipient_user_id: recipientUserId,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return undefined;
    }

    throw error;
  }
};

export const createMessageThread = async ({
  proposalId,
  userId,
  content,
  attachmentIds,
}: {
  proposalId?: number;
  userId: number;
  content: string;
  attachmentIds: number[];
}) => {
  const response = await request.post(`/message_threads`, {
    proposal_id: proposalId,
    user_id: userId,
    content,
    attachment_ids: attachmentIds,
  });
  return response.data;
};

export const uploadAttachment = async (
  file: File,
  abortSignal: AbortSignal,
  setUploadPercentage: Dispatch<SetStateAction<number | undefined>>
): Promise<MessageAttachmentAsJson | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    const response = await request.post("/message_attachments", formData, {
      signal: abortSignal,
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadPercentage(uploadPercentage);
      },
    });

    return response.data;
  } catch (error) {
    if (!abortSignal.aborted) {
      throw error;
    }
  }
};
