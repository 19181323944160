/**
 * USAGE:
 * import toast from '@components/Toast';
 * toast.success("success message");
 * toast.error("error message");
 */

import { ReactElement } from "react";
import styled from "styled-components";
import { toast as toastify, ToastContainer } from "react-toastify";
import { COLORS, FONTS } from "@constants";

export const Toaster = styled(ToastContainer).attrs(() => ({
  enableMultiContainer: true,
  containerId: "new",
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  rtl: false,
  pauseOnFocusLoss: false,
  theme: "colored",
  draggable: true,
  pauseOnHover: true,
}))`
  && {
    &.Toastify__toast-container {
      z-index: 1005;
      width: 658px;
      text-align: center;
      transform: translateX(-50%);
    }
    .Toastify__toast {
      border-radius: 4px;
      border-color: ${COLORS.GREEN};
    }
    .Toastify__toast-body {
      ${FONTS.MEDIUM_1}
      color: ${COLORS.WHITE};
      margin: 0 auto;
      flex: none;
      max-width: 100%;

      a {
        color: ${COLORS.WHITE};
        text-decoration: underline;
      }
    }
    .success {
      background-color: ${COLORS.GREEN};
    }
    .error {
      background-color: ${COLORS.RED};
    }
  }
`;

const toast = {
  success: (message: string | ReactElement) => {
    toastify.success(message, { containerId: "new", className: "success" });
  },
  error: (message: string | ReactElement) => {
    toastify.error(message, { containerId: "new", className: "error" });
  },
};

export default toast;
