import { useState, ReactNode } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";

interface Props {
  children: ReactNode;
  content: string | ReactNode;
  width?: string;
  arrowOffsetTop?: string | 0;
  tooltipOffsetTop?: string | 0;
  "data-testid"?: string;
}

const InputTooltip = ({
  children,
  content,
  width,
  arrowOffsetTop,
  tooltipOffsetTop,
  "data-testid": dataTestId,
}: Props) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <Container onFocus={() => setIsInputFocused(true)} onBlur={() => setIsInputFocused(false)}>
      {children}
      <TooltipContainer top={tooltipOffsetTop} isInputFocused={isInputFocused}>
        <Tooltip width={width} data-testid={dataTestId}>
          <Arrow top={arrowOffsetTop} />
          {content}
        </Tooltip>
      </TooltipContainer>
    </Container>
  );
};

export default InputTooltip;

const Container = styled.div`
  display: flex;
  position: relative;
`;
const TooltipContainer = styled.div`
  display: ${({ isInputFocused }) => (isInputFocused ? "initial" : "none")};
  position: absolute;
  right: -16px;
  transform: translateX(100%);
  ${({ top }) => top && `top: ${top}`};
`;
const Tooltip = styled.div`
  position: relative;
  background-color: ${COLORS.BLACK};
  padding: 16px;
  border-radius: 8px;
  color: ${COLORS.WHITE};
  ${FONTS.REGULAR_2};
  ${({ width }) => width && `width: ${width}`};
`;
const Arrow = styled.div`
  position: absolute;
  left: -7px;
  top: ${({ top }) =>
    top ?? "44px"}; // 44px aligns with the top row of an input that includes a label
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid ${COLORS.BLACK};
  width: 0;
  height: 0;
`;
