import Tooltip from "@components/library/Tooltips/Tooltip";
import { COLORS, LINKS } from "@constants";
import { t } from "@utils/i18n";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}
export function PremiumTagTooltip({ children }: Props) {
  return (
    <div>
      <Tooltip
        shouldFlex
        tooltipWidth="190px"
        content={<>{t("components.premium_tag_tooltip.text")} </>}
      >
        {children}
      </Tooltip>
    </div>
  );
}

export const WhiteLink = styled(Link)`
  color: ${COLORS.WHITE};
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.WHITE};
  }
`;
