import { COLORS, STYLES, WINDOW_DIMENSIONS } from "@constants";
import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  hasTopDivider?: boolean;
  hasPaddingTop?: boolean;
  hasPaddingBottom?: boolean;
  children: ReactNode;
}

interface ContainerProps {
  hasTopDivider: boolean;
  hasPaddingTop: boolean;
  hasPaddingBottom: boolean;
}

const PageContainer = ({
  hasTopDivider = false,
  hasPaddingTop = true,
  hasPaddingBottom = true,
  children,
}: Props) => {
  return (
    <Container
      hasTopDivider={hasTopDivider}
      hasPaddingTop={hasPaddingTop}
      hasPaddingBottom={hasPaddingBottom}
    >
      <Children>{children}</Children>
    </Container>
  );
};

export default PageContainer;

const Container = styled.div<ContainerProps>`
  padding: ${({ hasPaddingTop, hasPaddingBottom }) => `${hasPaddingTop ? "69px" : "0px"} ${
    STYLES.SIDE_PADDING
  }px
    ${hasPaddingBottom ? "69px" : "0px"}`};

  ${({ hasTopDivider }: ContainerProps) =>
    hasTopDivider && `border-top: 1px solid ${COLORS.NEUTRAL_100};`}

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    padding: 16px;
  }
`;
const Children = styled.div`
  max-width: ${STYLES.MAX_WINDOW_WIDTH}px;
  margin: 0 auto; ;
`;
