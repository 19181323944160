import { COLORS, FONTS } from "@constants";
import styled from "styled-components";

const KeywordsDropdownCreateLabel = (inputValue: string) => (
  <Label>
    If none of these terms are good options, <Add>add {inputValue}</Add>
  </Label>
);

export default KeywordsDropdownCreateLabel;

const Label = styled.div`
  ${FONTS.REGULAR_3}
  color: ${COLORS.NEUTRAL_400};
`;

const Add = styled.button`
  text-decoration: underline;
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
  }
`;
