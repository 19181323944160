import { ChangeEvent } from "react";
import styled from "styled-components";
import { RemovableInputWrapper, ChipCheckbox } from "@components/library";

interface Props {
  label: string;
  options: { value: string; label: string }[];
  selectedValues: string[] | null;
  disabledValues?: string[];
  onChange: (values: string[] | null) => void;
  "data-testid"?: string;
}

const ChipsInput = ({
  label,
  options,
  selectedValues,
  disabledValues = [],
  onChange,
  "data-testid": dataTestId,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    let newValues: string[];
    if (e.target.checked) {
      newValues = [...(selectedValues ?? []), value];
    } else {
      newValues = (selectedValues ?? []).filter((selectedValue) => selectedValue !== value);
    }
    onChange(newValues);
  };

  const areAllSelected = selectedValues?.length === options.length - disabledValues.length;

  const handleAllChange = () => {
    if (areAllSelected) {
      onChange([]);
    } else {
      const nonDisabledValues = options
        .map((option) => option.value)
        .filter((value) => !disabledValues.includes(value));
      onChange(nonDisabledValues);
    }
  };

  return (
    <div data-testid={dataTestId}>
      <RemovableInputWrapper
        label={label}
        onRemove={() => onChange(null)}
        isInitiallyOpen={Number(selectedValues?.length) > 0}
        data-testid={`${dataTestId}-wrapper`}
      >
        <Chips>
          <ChipCheckbox label="All" checked={areAllSelected} onChange={handleAllChange} />
          {options.map((option) => (
            <ChipCheckbox
              key={option.value}
              label={option.label}
              checked={Boolean(selectedValues?.includes(option.value))}
              disabled={disabledValues.includes(option.value)}
              onChange={(e) => handleChange(e, option.value)}
            />
          ))}
        </Chips>
      </RemovableInputWrapper>
    </div>
  );
};

export default ChipsInput;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
`;
