const PROPOSAL_STATUSES = {
  // Order
  PENDING: -1, // -1
  OPEN: 0, // 0
  QUALIFIED: 3, // 3
  SHORTLISTED: 4, // 4
  FINALIST: 5, // 5
  DECLINED: 2, // 2
  FUNDED: 6,
};

const PROPOSAL_STATUS_TO_TAB = {
  0: 0,
  3: 1,
  4: 2,
  5: 3,
  2: 4,
};

export { PROPOSAL_STATUS_TO_TAB };
export default PROPOSAL_STATUSES;
