export enum RfpPartnershipModel {
  SPONSORED_RESEARCH = "SPONSORED_RESEARCH",
  GIFT = "GIFT",
  CO_DEVELOPMENT = "CO_DEVELOPMENT",
  EQUITY_INVESTMENT = "EQUITY_INVESTMENT",
  ACQUISITION = "ACQUISITION",
  SUPPLY_PURCHASE = "SUPPLY_PURCHASE",
  LICENSING = "LICENSING",
  MATERIAL_TRANSFER = "MATERIAL_TRANSFER",
}

export const PARTNERSHIP_MODEL_LABELS = {
  [RfpPartnershipModel.SPONSORED_RESEARCH]: "Sponsored research",
  [RfpPartnershipModel.GIFT]: "Gift (Unrestricted grant)",
  [RfpPartnershipModel.CO_DEVELOPMENT]: "Co-development",
  [RfpPartnershipModel.EQUITY_INVESTMENT]: "Equity investment",
  [RfpPartnershipModel.ACQUISITION]: "Acquisition",
  [RfpPartnershipModel.SUPPLY_PURCHASE]: "Supply/purchase",
  [RfpPartnershipModel.LICENSING]: "Licensing",
  [RfpPartnershipModel.MATERIAL_TRANSFER]: "Material transfer",
};

export enum RfpBenefitType {
  SPONSORED_RESEARCH = "sponsored_research",
  GIFT = "gift",
  EXPERTISE = "expertise",
  TOOLS_AND_TECHNOLOGY = "tools_and_technology",
  COMPOUNDS_AND_REAGENTS = "compounds_and_reagents",
  FACILITIES_AND_SERVICES = "facilities_and_services",
  DATA = "data",
}

export enum ReviewTeamRole {
  NORMAL = 0,
  ADMIN = 1,
  EXPERT = 2,
}

export enum RfpStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  IN_EXTENSION = "IN_EXTENSION",
}

export enum RfpFeedbackDeadlineStatus {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NEEDS_DEADLINE = "NEEDS_DEADLINE",
  BEFORE_DEADLINE = "BEFORE_DEADLINE",
  DEADLINE_APPROACHING = "DEADLINE_APPROACHING",
  PAST_DEADLINE = "PAST_DEADLINE",
}
