import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { OnChangeValue, components } from "react-select";
import Validator from "validator";
import { usedPersonalEmail } from "@utils/appUtils";
import UserDropdown, { UserOption } from "./UserDropdown";

interface DropdownProps {
  options: UserOption[];
  value: UserOption[];
  placeholder?: string;
  maxMenuHeight?: string;
  maxValueContainerHeight?: string;
  onChange: (any) => void;
  setError: Dispatch<SetStateAction<string>>;
  formatCreateLabel?: (input: string) => string;
  isPortal?: boolean;
}

const EmailDropdown: FunctionComponent<DropdownProps> = ({
  options,
  value,
  maxMenuHeight = "250px",
  maxValueContainerHeight = "unset",
  placeholder = "Search for teammates by name or email",
  onChange,
  setError,
  formatCreateLabel = (input: string) => `Share with '${input}'`,
  isPortal = false,
}) => {
  const handleChange = (newValue: OnChangeValue<UserOption, true>) => {
    const emails: string[] = newValue.map((e) => e.value);
    onChange(emails);

    // Check for invalid emails to set error
    let errorMsg = "";
    emails.forEach((email) => {
      if (!Validator.isEmail(email)) {
        errorMsg = `'${email}' is not a valid email address.`;
      } else if (usedPersonalEmail(email)) {
        errorMsg = `Please use a company email.`;
      }
    });

    setError(errorMsg);
  };

  return (
    <UserDropdown
      value={value}
      options={options}
      placeholder={placeholder}
      maxMenuHeight={maxMenuHeight}
      maxValueContainerHeight={maxValueContainerHeight}
      onChange={handleChange}
      formatCreateLabel={formatCreateLabel}
      creatable
      isPortal={isPortal}
    />
  );
};

export default EmailDropdown;
