import { COLORS } from "@constants";
import Tag from "../Tag";

export function AdminTag() {
  return (
    <Tag
      content="Admin"
      size="xs"
      backgroundColor={COLORS.BLUE_LIGHT_350}
      color={COLORS.BLUE_650}
      data-testid="admin-tag"
    />
  );
}
