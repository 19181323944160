export enum RequestableType {
  OPEN_CALL = "OpenCall",
  RFP = "Rfp",
  REQUEST_FOR_STARTUP = "RequestForStartup",
}

export enum LaunchableRequestStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  COMING_SOON = "COMING_SOON",
  IN_EXTENSION = "IN_EXTENSION",
}
