import { COLORS, FONTS } from "@constants";

import styled from "styled-components";
import Icon from "../Icon";
import InfoTag from "../Tags/InfoTag";

interface Props {
  variant: "ghost" | "fourth";
  count: number;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

function FiltersButton({ variant, count, isOpen, setIsOpen }: Props) {
  const hasFilters = count > 0;

  let iconColor: string;
  let countTagBackgroundColor: string;
  switch (variant) {
    case "ghost": {
      iconColor = COLORS.HALO_BLUE;
      countTagBackgroundColor = COLORS.BLUE_650;
      break;
    }
    case "fourth": {
      iconColor = COLORS.NEUTRAL_800;
      countTagBackgroundColor = COLORS.BLACK;
      break;
    }
  }

  return (
    <Button
      variant={variant}
      hasFilters={hasFilters}
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Content variant={variant} hasFilters={hasFilters}>
        <Icon name="Filter" size="md" color={iconColor} />
        Filters
        {hasFilters && (
          <InfoTag backgroundColor={countTagBackgroundColor} marginLeft="0">
            {count}
          </InfoTag>
        )}
      </Content>
    </Button>
  );
}

export default FiltersButton;

const Button = styled.button`
  height: 36px;
  border: none;
  border-radius: 4px;
  padding: 0;
  background-color: ${({ hasFilters, variant }) => {
    if (!hasFilters) return "transparent";
    switch (variant) {
      case "ghost": {
        return COLORS.BLUE_LIGHT_300;
      }
      case "fourth": {
        return COLORS.NEUTRAL_200;
      }
    }
  }};
  outline: 1px solid
    ${({ hasFilters, isOpen, variant }) => {
      if (!(hasFilters || isOpen)) return "transparent";
      switch (variant) {
        case "ghost": {
          return COLORS.HALO_BLUE;
        }
        case "fourth": {
          return COLORS.NEUTRAL_800;
        }
      }
    }};

  &:hover > div {
    background-color: ${({ variant, hasFilters }) => {
      switch (variant) {
        case "ghost": {
          return hasFilters ? COLORS.BLUE_LIGHT_500 : COLORS.BLUE_LIGHT_300;
        }
        case "fourth": {
          return hasFilters ? COLORS.NEUTRAL_350 : COLORS.NEUTRAL_200;
        }
      }
    }};
  }

  &:active {
    & > div {
      background-color: ${({ variant, hasFilters }) => {
        switch (variant) {
          case "ghost": {
            return hasFilters ? COLORS.BLUE_LIGHT_600 : COLORS.BLUE_LIGHT_400;
          }
          case "fourth": {
            return hasFilters ? COLORS.NEUTRAL_400 : COLORS.NEUTRAL_300;
          }
        }
      }};
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 100%;
  height: 100%;

  ${FONTS.BUTTON_2}
  border-radius: 4px;
  color: ${({ variant }) => {
    switch (variant) {
      case "ghost": {
        return COLORS.HALO_BLUE;
      }
      case "fourth": {
        return COLORS.NEUTRAL_800;
      }
    }
  }};
  padding: 0 20px 0 12px;
  transition: background-color 0.1s ease;
`;
