import { ReactNode } from "react";
import styled from "styled-components";

import { COLORS, FONTS } from "@constants";
import BlankModal, { BlankModalProps } from "../ModalBases/BlankModal";
import Button from "../Buttons/Button";
import Icon from "../Icon";

interface Props {
  message?: ReactNode;
}

const ErrorModal = ({
  message,
  isOpen,
  onClose,
}: Props & Pick<BlankModalProps, "isOpen" | "onClose">) => {
  if (!isOpen || !message) return null;

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      width="462px"
      closeOnDimmerClick={false}
      disableBackgroundScroll
      hideCloseButton
    >
      <Content>
        <Body>
          <Icon name="Attention Filled" size="xl" color={COLORS.RED} />
          <div>
            <Message>{message}</Message>
          </div>
        </Body>
        <ButtonContainer>
          <Button width="132px" onClick={onClose}>
            Got it
          </Button>
        </ButtonContainer>
      </Content>
    </BlankModal>
  );
};

export default ErrorModal;

const Content = styled.div`
  padding: 40px 40px 24px;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 44px 1fr;
  grid-gap: 12px;
`;

const Message = styled.div`
  ${FONTS.HEADING_5_SEMIBOLD}
  line-height: 28px;
  color: ${COLORS.RED_600};
`;

const ButtonContainer = styled.div`
  margin-top: 56px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
