import { ReactElement } from "react";
import styled, { css } from "styled-components";
import { COLORS, FONTS } from "@constants";
import { BaseButtonProps } from "./Button";
import { Link } from "react-router-dom";

interface Props extends BaseButtonProps {
  size?: "md" | "lg";
  // This sets a min-width in case the button is intended to extend past its contents.  A minimum padding will be maintained
  width?: string;
  maxHeight?: string;
  icon?: HTMLImageElement | ReactElement<any, any> | null;
  iconPosition?: "left" | "right";
  children: any;
  to?: string;
  className?: string;
}

const GradientButton = ({
  size = "lg",
  width,
  maxHeight,
  to,
  margin = 0,
  icon,
  iconPosition = "left",
  children,
  id,
  disabled,
  type = "button",
  onClick,
  "data-testid": dataTestId,
  className,
}: Props) => {
  const StyledComponent = to ? StyledLink : StyledButton;

  return (
    <StyledComponent
      to={to}
      size={size}
      width={width}
      height={maxHeight}
      margin={margin}
      iconPosition={iconPosition}
      id={id}
      disabled={disabled}
      type={type}
      onClick={onClick}
      data-testid={dataTestId}
      className={className}
    >
      {icon ? <IconContainer iconPosition={iconPosition}>{icon}</IconContainer> : null}
      {children}
    </StyledComponent>
  );
};

export default GradientButton;

const BaseStyles = css`
  display: flex;
  flex-direction: ${({ iconPosition }) => (iconPosition === "left" ? "row" : "row-reverse")};
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === "md" ? "42px" : "64px")};
  min-width: ${({ width }) => width ?? "initial"};
  max-height: ${({ maxHeight }) => maxHeight ?? "initial"};
  background: ${COLORS.BLUE_GRADIENT};
  color: ${COLORS.WHITE};
  font-size: ${({ size }) => (size === "md" ? "16px" : "18px")};
  font-weight: 500;
  line-height: ${({ size }) => (size === "md" ? "16px" : "18px")};
  border: none;
  border-radius: ${({ size }) => (size === "md" ? "4px" : "6px")};
  box-shadow: 0px 10px ${({ size }) => (size === "md" ? 16 : 32)}px -4px #016aaa54;
  padding: 0 24px;
  margin: ${({ margin }) => margin};
  &:hover {
    background: linear-gradient(92.65deg, #004980 0%, #0085cc 92%);
  }
  &:focus:not(:active) {
    outline: none;
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  ${BaseStyles}
  ${FONTS.HEADING_5_MEDIUM}
  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const StyledButton = styled.button`
  ${BaseStyles}
`;
const IconContainer = styled.div`
  margin: ${({ iconPosition }) => (iconPosition === "left" ? "0 4px 0 0" : "0 0 0 4px")};
  width: 24px;
  height: 24px;
`;
