const BLOCKED_EMAIL_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "msn.com",
  "me.com",
  "icloud.com",
  "ymail.com",
  "att.net",
  "verizon.net",
  "outlook.com",
  "proton.com",
  "qq.com",
  "163.com",
  "mac.com",
  "proton.me",
  "protonmail.com",
  "pm.me",
  "yahoo.co.uk",
];

export default BLOCKED_EMAIL_DOMAINS;
