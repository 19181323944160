import { css } from "styled-components";
import { COLORS, FONTS, STYLES } from "@constants";

// Only make changes here that are intended to be applicable to all standard dropdowns!
// This should go first in a dropdown's styles. If a particular dropdown needs specific
// styles, that should go into that dropdown's styles

const DROPDOWN_STYLES = css`
  .Select__control {
    border: 1px solid ${COLORS.NEUTRAL_300};
    min-height: 36px;
    transition: initial;
    &:hover {
      &:not(.Select__control--is-focused) {
        border: 1px solid ${COLORS.NEUTRAL_400};
        cursor: pointer;
      }
      &.Select__control--is-focused {
        border: 1px solid ${COLORS.HALO_BLUE};
      }
      cursor: pointer;
    }
  }
  .Select__control--is-focused {
    box-shadow: none;
    border: 1px solid ${COLORS.HALO_BLUE};
  }
  .Select__placeholder {
    color: ${COLORS.NEUTRAL_500};
    ${FONTS.REGULAR_2};
  }
  .Select__input-container {
    ${FONTS.REGULAR_2};
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__indicator {
    color: ${COLORS.BLACK};
    margin-right: 10px;
    padding: 0;
    &:hover {
      color: ${COLORS.BLACK};
    }
  }
  .Select__menu {
    white-space: nowrap;
    width: 100%;
    border-radius: 8px;
    padding: 16px 12px;
    box-shadow: ${STYLES.SHADOW_B};
    cursor: default;
  }
  .Select__menu-list {
    max-height: unset;
  }
  .Select__group {
    padding: 0;
  }
  .Select__option {
    min-width: 225px;
    margin: 0 12px;
    width: calc(100% - 24px);
    border-radius: 4px;
    background-color: ${COLORS.WHITE};
    ${FONTS.REGULAR_2};
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.NEUTRAL_100};
    }
    &:active {
      background-color: ${COLORS.NEUTRAL_200};
    }
  }
  .Select__option--is-selected {
    ${FONTS.MEDIUM_2};
    color: ${COLORS.HALO_BLUE};
  }
`;

export default DROPDOWN_STYLES;

export const MULTI_SELECT_TAG_STYLES = css`
  .Select__input-container {
    margin: 0 2px;
  }
  .Select__multi-value {
    display: flex;
    align-items: center;
    height: 24px;
    background-color: ${COLORS.NEUTRAL_100};
    padding: 0 14px;
    border-radius: 100px;
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
  .Select__multi-value__label {
    ${FONTS.MEDIUM_3};
    padding: 0;
  }
  .Select__multi-value__remove {
    padding: 0;
    &:hover {
      opacity: 0.4;
      background-color: transparent;
    }
  }
`;
