import { ReactNode } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import DROPDOWN_STYLES from "./dropdownStyles";
import Select, { components } from "react-select";
import { TextButton } from "@components/library";
import { DropdownOption, ExtendedDropdownOption, OptionLabel } from "./DropdownOption";

interface Props {
  children?: ReactNode; // Trigger component. Defaults to a TextButton with the value label
  options: ExtendedDropdownOption[];
  value: DropdownOption;
  onChange: (...args: any[]) => any;
  isSearchable?: boolean;
  menuWidth?: string;
  maxMenuHeight?: string;
  menuHorizontalPosition?: "right" | "left";
  // Portal the menu with width if using within a scrollable overflow
  isPortal?: boolean;
  portalMenuWidth?: string;
}

const DropdownIndicator = (props) => {
  const { children, value } = props.selectProps;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      {children ?? <TextButton text={value.label} color={COLORS.BLACK} />}
    </components.DropdownIndicator>
  );
};

// This dropdown is used when a selected option becomes the value of the dropdown. Often times, the
// display will directly reflect the selected option. A typical use case would be a "sort by" menu
const SelectableDropdown = ({
  children,
  options,
  value,
  onChange,
  isSearchable = false,
  menuWidth,
  maxMenuHeight,
  menuHorizontalPosition,
  isPortal,
  portalMenuWidth = "300px",
}: Props) => {
  return (
    <Dropdown
      classNamePrefix="Select"
      components={{ DropdownIndicator }}
      options={options}
      formatOptionLabel={OptionLabel}
      value={value}
      onChange={(e: ExtendedDropdownOption) => onChange(e)}
      isSearchable={isSearchable}
      menuWidth={menuWidth}
      maxMenuHeight={maxMenuHeight}
      menuHorizontalPosition={menuHorizontalPosition}
      menuPosition={isPortal ? "fixed" : "absolute"}
      menuPortalTarget={isPortal && document.body}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          width: portalMenuWidth,
        }),
        option: (base, { isSelected }) => {
          return {
            ...base,
            color: COLORS.BLACK,
            ...(isSelected && { backgroundColor: COLORS.BLUE_LIGHT_400 }),
          };
        },
      }}
      menuShouldBlockScroll={isPortal}
    >
      {children}
    </Dropdown>
  );
};

export default SelectableDropdown;

const Dropdown = styled(Select)`
  ${DROPDOWN_STYLES};

  ${({ menuWidth }) =>
    menuWidth &&
    `
      .Select__menu {
        white-space: normal;
        width: ${menuWidth};

        position: absolute;
        right: 0;
      }
  `}
  .Select__control {
    padding: 0 2px 0 4px;
    min-height: unset;
    border: none;
    &:hover {
      &:not(.Select__control--is-focused) {
        border: none;
      }
      &.Select__control--is-focused {
        border: none;
      }
    }
  }
  .Select__placeholder {
    color: ${COLORS.BLACK};
    ${FONTS.MEDIUM_2};
  }
  .Select__value-container {
    height: 0;
    width: 0;
    padding: 0;
  }
  .Select__indicator {
    margin: 0px;
  }
  .Select__option {
    min-width: unset;
  }
  .Select__menu {
    min-width: fit-content;
    ${({ menuHorizontalPosition }) =>
      menuHorizontalPosition &&
      `
        position: absolute;
        ${menuHorizontalPosition}: 0;
    `}
  }
  .Select__menu-list {
    max-height: ${({ maxMenuHeight }) => maxMenuHeight};
  }
  .Select__control:hover:not(.Select__control--is-focused) {
    border: none;
  }
`;
