import { getData } from "country-list";

interface CountryData {
  code: string;
  name: string;
}

const countriesData: CountryData[] = getData();

const uk = countriesData.find((country) => country.code === "GB");

if (uk) {
  uk.name = "United Kingdom";
}

countriesData.sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }
  return 0;
});

const COUNTRY_OPTIONS = countriesData.map((countryData) => ({
  value: countryData.code,
  label: countryData.name,
}));

export default COUNTRY_OPTIONS;
