/* WINDOW DIMENSIONS */
const EXTRA_WIDE = 1920;
const DESKTOP = 1280;
const TABLET_LANDSCAPE = 991;
const TABLET = 767;
const MOBILE = 479;
/* MEDIA QUERIES */
const EXTRA_WIDE_MEDIA_QUERY = `@media screen and (min-width: ${EXTRA_WIDE}px)`;
const DESKTOP_MEDIA_QUERY = `@media screen and (max-width: ${DESKTOP}px)`;
const TABLET_LANDSCAPE_MEDIA_QUERY = `@media screen and (max-width: ${TABLET_LANDSCAPE}px)`;
const TABLET_MEDIA_QUERY = `@media screen and (max-width: ${TABLET}px)`;
const MOBILE_MEDIA_QUERY = `@media screen and (max-width: ${MOBILE}px)`;

// From Erik's research of active users
const BASE_WINDOW_WIDTH = 1350;

export default {
  EXTRA_WIDE,
  DESKTOP,
  TABLET_LANDSCAPE,
  TABLET,
  MOBILE,
  EXTRA_WIDE_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
  TABLET_LANDSCAPE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  BASE_WINDOW_WIDTH,
};
