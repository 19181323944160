import ReactDOMServer from "react-dom/server";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import i18n from "@locales";

export const t = (
  scope: string | string[],
  interpolations?: { [k: string]: string | number | null }
): string => {
  const finalScope = typeof scope === "string" ? scope : scope[scope.length - 1];
  if (finalScope.endsWith("_html"))
    throw new Error("HTML interpolation not allowed. Use t_html instead.");

  return i18n.t(scope, interpolations);
};

// Custom wrapper around t that allows for safe HTML interpolation of _html suffixed scopes
export const t_html = (
  scope: string | string[],
  interpolations?: { [k: string]: string | number | null }
): ReactElement => {
  const finalScope = typeof scope === "string" ? scope : scope[scope.length - 1];
  if (!finalScope.endsWith("_html")) throw new Error("No HTML interpolation. Use t instead.");

  const escapedInterpolations = interpolations
    ? Object.fromEntries(
        Object.entries(interpolations).map(([k, v]) => [k, ReactDOMServer.renderToString(v as any)])
      )
    : {};

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: i18n.t(scope, escapedInterpolations) }} />;
};

export const t_link = (
  scope: string | string[],
  link: Link | HTMLAnchorElement,
  interpolations?: { [k: string]: string | number | null }
): ReactElement => {
  if (!link || (!("to" in link.props) && !(link.type !== "a")))
    throw new Error("`link` must be a Link or anchor element");

  const finalScope = typeof scope === "string" ? scope : scope[scope.length - 1];

  const translation = finalScope.endsWith("_html")
    ? t_html(scope, interpolations).props.dangerouslySetInnerHTML.__html
    : t(scope, interpolations);

  if (!translation.includes('[missing "%{link}" value]'))
    throw new Error("Translation must include %{link}");

  const parts = translation.split('[missing "%{link}" value]');

  return (
    <span>
      {parts[0]}
      {link}
      {parts[1]}
    </span>
  );
};
