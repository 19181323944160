import { useState, useRef, ReactNode, useEffect } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";

interface Props {
  children: ReactNode;
  content: string | ReactNode;
  tooltipWidth?: string;
  contentWidth?: string;
  position?: "top" | "bottom" | "left" | "right";
  arrowOffsetTop?: string | 0;
  remainOnHover?: boolean;
  shouldWrap?: boolean;
  isActive?: boolean;
  shouldFlex?: boolean;
  "data-testid"?: string;
}

const Tooltip = ({
  children,
  content,
  tooltipWidth,
  contentWidth,
  position = "top",
  arrowOffsetTop,
  remainOnHover = true,
  shouldWrap = true,
  isActive = true,
  shouldFlex = false,
  "data-testid": dataTestId,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const childrenRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const toggleShow = (newState) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setShowTooltip(newState), 100);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const childrenHeight = childrenRef.current?.clientHeight ?? 0;
  const childrenVerticalCenter = `${childrenHeight / 2 - 8}px`;
  const top = arrowOffsetTop ?? childrenVerticalCenter;

  return (
    <Container contentWidth={contentWidth}>
      <ChildrenContainer
        onMouseEnter={() => toggleShow(true)}
        onMouseLeave={() => toggleShow(false)}
        ref={childrenRef}
        shouldFlex={shouldFlex}
      >
        {children}
      </ChildrenContainer>
      <StyledTooltip
        showTooltip={isActive && showTooltip}
        width={tooltipWidth}
        position={position}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onMouseEnter={() => remainOnHover && toggleShow(true)}
        onMouseLeave={() => toggleShow(false)}
        data-testid={dataTestId}
      >
        <ContentContainer shouldWrap={shouldWrap}>{content}</ContentContainer>
        <Arrow position={position} top={top} />
      </StyledTooltip>
    </Container>
  );
};

export default Tooltip;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  ${({ contentWidth }) => `width: ${contentWidth}`}
`;
const ChildrenContainer = styled.div`
  width: 100%;
  ${({ shouldFlex }) => shouldFlex && "display: flex;"}
`;
const StyledTooltip = styled.div`
  display: ${({ showTooltip }) => (showTooltip ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: absolute;
  ${({ position }) => {
    switch (position) {
      case "top":
        return `
          bottom: calc(100% + 12px);
        `;
      case "bottom":
        return `
          top: calc(100% + 12px);
        `;
      case "left":
        return `
          right: calc(100% + 12px);
        `;
      case "right":
        return `
          left: calc(100% + 12px);
        `;
    }
  }}
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  ${({ width }) => width && `width: ${width};`}
  ${({ shouldWrap }) => !shouldWrap && `white-space: nowrap;`}
  border-radius: 6px;
  padding: 12px;
  text-align: left;
  ${FONTS.REGULAR_3};
  z-index: 1;
  cursor: auto;
`;
const ContentContainer = styled.div`
  width: 100%;
  white-space: ${({ shouldWrap }) => (shouldWrap ? "normal" : "no-wrap")};
`;
const Arrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  ${({ position, top }) => {
    switch (position) {
      case "top":
        return `
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid ${COLORS.BLACK};
          bottom: -8px;
        `;
      case "bottom":
        return `
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid ${COLORS.BLACK};
          top: -8px;
        `;
      case "left":
        return `
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid ${COLORS.BLACK};
          right: -8px;
          top: ${top};
        `;
      case "right":
        return `
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 8px solid ${COLORS.BLACK};
          left: -8px;
          top: ${top};
        `;
    }
  }}
`;
