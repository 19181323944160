import { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from "react";
import styled, { css } from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";
import resizeGrabber from "@images/icons/resize_grabber.svg";

interface Props {
  id?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: ReactNode;
  labelFont?: string;
  placeholder?: string;
  placeholderColor?: string;
  onBlur?: (...args: any[]) => void;
  optional?: boolean;
  characterLimit?: number;
  warningLimit?: number;
  errors?: { hasError: boolean; errorMessage: string }[];
  textarea?: boolean;
  type?: string;
  hideClearButton?: boolean;
  resize?: string;
  startingHeight?: string;
  min?: number;
  max?: number;
  "data-testid"?: string;
}

const TextInput = (
  {
    id,
    value,
    onChange,
    label,
    labelFont,
    placeholder,
    placeholderColor,
    type = "text",
    onBlur,
    optional = false,
    characterLimit,
    warningLimit,
    errors,
    hideClearButton = false,
    textarea = false,
    resize = "vertical",
    startingHeight,
    min,
    max,
    "data-testid": dataTestId,
  }: Props,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>
) => {
  const hasExceededCharacterLimit = characterLimit && value.length > characterLimit;
  const hasExceededWarningLimit = warningLimit && value.length > warningLimit;
  const hasAnyError =
    // If the other error conditions are all false, we still want to check the character limit
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    errors?.map((error) => error.hasError).includes(true) || hasExceededCharacterLimit;

  const handleClear = (e) => {
    const newEvent = {
      ...e,
      target: {
        ...e.target,
        value: type === "number" ? undefined : "",
      },
    };

    onChange(newEvent);
  };

  const input = textarea ? (
    <TextArea
      id={id}
      autoComplete="off"
      placeholder={placeholder}
      placeholderColor={placeholderColor}
      value={value}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e)}
      onBlur={onBlur}
      ref={ref}
      startingHeight={startingHeight}
      resize={resize}
      hasError={hasAnyError}
      data-testid={dataTestId}
    />
  ) : (
    <InputContainer hasError={hasAnyError}>
      <Input
        id={id}
        type={type}
        autoComplete="off"
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
        onBlur={onBlur}
        ref={ref}
        hasError={hasAnyError}
        hideClearButton={hideClearButton}
        min={min}
        max={max}
        data-testid={dataTestId}
      />
      {value && !hideClearButton && (
        <ClearButton type="button" onClick={(e) => handleClear(e)}>
          <Icon name="X" size="xs" />
        </ClearButton>
      )}
    </InputContainer>
  );

  const errorIcon = <Icon name="Attention" size="sm" margin="0 8px 0 0" color={COLORS.RED_600} />;

  return (
    <Container>
      {label ? (
        <Label labelFont={labelFont}>
          <LabelText hasError={hasAnyError}>
            {label}
            {optional && <Optional>Optional</Optional>}
          </LabelText>
          {input}
        </Label>
      ) : (
        input
      )}
      {characterLimit && (
        <CharacterLimit hasError={hasExceededCharacterLimit} hasWarning={hasExceededWarningLimit}>
          {hasExceededCharacterLimit && errorIcon}
          {value.length} of {characterLimit} characters including spaces
        </CharacterLimit>
      )}
      {errors?.map(
        (error) =>
          error.hasError && (
            <ErrorRow key={error.errorMessage} data-testid={`${dataTestId}-error`}>
              {errorIcon}
              {error.errorMessage}
            </ErrorRow>
          )
      )}
    </Container>
  );
};

export default forwardRef(TextInput);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
// These styles are also used in MultiTextInput and SelectOrCreateDropdown
export const Label = styled.label`
  ${({ labelFont }) => labelFont ?? FONTS.SEMIBOLD_2};
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
`;
export const LabelText = styled.div`
  display: flex;
  color: ${({ hasError }) => (hasError ? COLORS.RED_600 : COLORS.BLACK)};
`;
export const Optional = styled.span`
  position: relative;
  top: 2px;
  ${FONTS.MEDIUM_2};
  color: ${COLORS.NEUTRAL_500};
  margin-left: 16px;
`;
const inputStyles = css`
  ${FONTS.REGULAR_2};
  border: 1px solid ${({ hasError }) => (hasError ? COLORS.RED_600 : COLORS.NEUTRAL_300)};
  border-radius: 6px;
  height: 36px;
  width: 100%;
  ${({ hasError }) => hasError && `background-color: ${COLORS.RED_100}`};
  &::placeholder {
    ${FONTS.REGULAR_2};
    ${({ placeholderColor }) =>
      placeholderColor
        ? `
      opacity: 1;
      color: ${placeholderColor};
    `
        : `
      color: COLORS.NEUTRAL_400;
    `}
  }
  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? COLORS.RED_600 : COLORS.HALO_BLUE)};
  }
`;
const TextArea = styled.textarea`
  ${inputStyles};
  padding: 6px 10px;
  height: ${({ startingHeight }) => startingHeight ?? "84px"};
  min-height: 38px;
  resize: ${({ resize }) => resize};
  &::-webkit-resizer {
    background-image: url(${resizeGrabber});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom right;
  }
  &::-webkit-scrollbar-corner {
    height: unset;
  }
  &:hover:not(:focus) {
    border: 1px solid ${({ hasError }) => (hasError ? COLORS.RED_600 : COLORS.NEUTRAL_500)};
  }
`;
const InputContainer = styled.div`
  position: relative;
  &:hover input:not(:focus) {
    border: 1px solid ${({ hasError }) => (hasError ? COLORS.RED_600 : COLORS.NEUTRAL_500)};
  }
`;
const Input = styled.input`
  ${inputStyles};
  padding: ${({ hideClearButton }) => (hideClearButton ? "6px 10px" : "6px 36px 6px 10px")};
`;
const ClearButton = styled.button`
  position: absolute;
  top: calc(50% - 8px);
  right: 15px;
  border: none;
  background: none;
  padding: 0;
  &:hover {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
`;
const CharacterLimit = styled.div`
  display: flex;
  ${({ hasError, hasWarning }) => (hasError || hasWarning ? FONTS.MEDIUM_3 : FONTS.REGULAR_3)};
  color: ${({ hasError, hasWarning }) => {
    if (hasError) return COLORS.RED_600;
    else if (hasWarning) return COLORS.ORANGE;
    return COLORS.BLACK;
  }};
`;
const ErrorRow = styled.div`
  display: flex;
  ${FONTS.MEDIUM_3};
  color: ${COLORS.RED_600};
`;
