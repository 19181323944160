// Note: SearchBar and MultiSelectDropdown are not using this component, though are styled
// to look the same, due to the way React-Select handles multi-select tags

import { ReactNode } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";
import { IconName } from "../Icon/Icon";

export type TagTheme = "light" | "dark" | "neutral";

export interface TagProps {
  content: ReactNode;
  iconName?: IconName;
  size?: "xs" | "sm" | "md";
  font?: string;
  theme?: TagTheme;
  isValid?: boolean;
  onRemove?: (...args: any[]) => any;
  id?: string;
  "data-testid"?: string;
  // Style override props
  backgroundColor?: string;
  color?: string;
  margin?: string | 0;
  border?: string;
}

const ICON_SIZE = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
};

const ICON_MARGIN = {
  xs: "0 3px 0 0",
  sm: "0 4px 0 0",
  md: "0 6px 0 0",
};

const Tag = ({
  content,
  iconName,
  size = "sm",
  font,
  theme = "neutral",
  isValid = true,
  onRemove,
  id,
  "data-testid": dataTestId,
  backgroundColor,
  color,
  margin = 0,
  border,
}: Props) => (
  <StyledTag
    id={id}
    size={size}
    theme={theme}
    isValid={isValid}
    backgroundColor={backgroundColor}
    color={color}
    font={font}
    margin={margin}
    border={border}
    data-testid={dataTestId}
  >
    {iconName && (
      <Icon name={iconName} size={ICON_SIZE[size]} color={color} margin={ICON_MARGIN[size]} />
    )}
    {content}
    {onRemove && (
      <div onClick={onRemove}>
        <Icon name="X" size="xs" color={isValid ? COLORS.BLACK : COLORS.RED} />
      </div>
    )}
  </StyledTag>
);

const BACKGROUND_TYPE = {
  light: COLORS.WHITE,
  dark: COLORS.NEUTRAL_200,
  neutral: COLORS.NEUTRAL_100,
};

const XS_STYLES = `
  height: 16px;
  padding: 0 8px;
  ${FONTS.TAG_MEDIUM_3}
`;

const SM_STYLES = `
  height: 24px;
  padding: 0 14px;
  ${FONTS.TAG_MEDIUM_2}
`;

const MD_STYLES = `
  height: 36px;
  padding: 0 16px;
  ${FONTS.TAG_SEMIBOLD_1}
`;

const StyledTag = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: none;
  border-radius: 100px;
  background-color: ${({ backgroundColor, theme, isValid }) =>
    (!isValid && COLORS.RED_100) || backgroundColor || BACKGROUND_TYPE[theme]};
  color: ${({ color, isValid }) => (!isValid && COLORS.RED) || color || COLORS.BLACK};
  ${({ size }) => {
    switch (size) {
      case "xs":
        return XS_STYLES;
      case "sm":
        return SM_STYLES;
      case "md":
        return MD_STYLES;
    }
  }}
  white-space: nowrap;
  margin: ${({ margin }) => margin};
  border: ${({ border }) => border};
  ${({ font }) => font && font};

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;

export default Tag;
