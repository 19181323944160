import styled from "styled-components";
import { FONTS, COLORS, WINDOW_DIMENSIONS } from "@constants";
import Icon, { IconName } from "@components/library/Icon/Icon";
import { ReactElement } from "react";

export interface DropdownOption {
  value: string;
  label: string;
}

export interface DropdownOptionGroup {
  label: string;
  hasDivider?: boolean;
  iconName?: IconName;
  options: DropdownOption[];
}

export type DropdownOptionOrGroup = DropdownOption | DropdownOptionGroup;

export interface ExtendedDropdownOption extends DropdownOption {
  onSelect?: (...args: any[]) => any;
  iconName?: IconName;
  optionDescription?: string;
  optionColor?: string;
  rightLabelEl?: ReactElement;
  isDisabled?: boolean;
}

export interface ExtendedDropdownOptionGroup extends DropdownOptionGroup {
  options: ExtendedDropdownOption[];
}

export type ExtendedDropdownOptionOrGroup = ExtendedDropdownOption | ExtendedDropdownOptionGroup;

// Only used in SelectableDropdown
export const OptionLabel = (option: ExtendedDropdownOption, metaData) => {
  const isActive = metaData.selectValue[0]?.value === option.value;

  return (
    <Option color={option.optionColor} optionDescription={option.optionDescription}>
      {option.iconName ? (
        <Icon
          name={option.iconName}
          margin="0 10px 0 0"
          color={isActive ? COLORS.HALO_BLUE : option.optionColor}
        />
      ) : null}
      <Content>
        <LabelContainer>
          {option.label}
          {option.rightLabelEl}
        </LabelContainer>
        {option.optionDescription && (
          <OptionDescription isDisabled={option.isDisabled}>
            {option.optionDescription}
          </OptionDescription>
        )}
      </Content>
    </Option>
  );
};

// Only used in MoreActionsDropdown
export const MoreActionsOptionLabel = (option: ExtendedDropdownOption) => {
  return (
    <MoreActionsOption color={option.optionColor} optionDescription={option.optionDescription}>
      {option.iconName ? (
        <Icon name={option.iconName} margin="0 10px 0 0" color={option.optionColor} />
      ) : null}
      <Content>
        <LabelContainer>
          {option.label}
          {option.rightLabelEl}
        </LabelContainer>
        {option.optionDescription && (
          <OptionDescription isDisabled={option.isDisabled}>
            {option.optionDescription}
          </OptionDescription>
        )}
      </Content>
    </MoreActionsOption>
  );
};

const Option = styled.div`
  display: flex;
  color: ${({ color }) => color};
  align-items: ${({ optionDescription }) => (optionDescription ? "flex-start" : "center")};
`;

const MoreActionsOption = styled(Option)`
  ${FONTS.SEMIBOLD_2};
`;

const Content = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const OptionDescription = styled.div`
  ${FONTS.REGULAR_3};
  color: ${({ isDisabled }) => (isDisabled ? COLORS.NEUTRAL_350 : COLORS.NEUTRAL_400)};

  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    display: none;
  }
`;
