import { useEffect, useState } from "react";
import api from "@requests/request";
import { getOptionFromValue } from "@utils/dropdownUtils";
import { DropdownOption } from "@components/library/Dropdowns/DropdownOption";

/**
 * @returns {[companyOptions, addCompanyOption]}
 */
function useCompanyOptions() {
  const [companyOptions, setCompanyOptions] = useState<DropdownOption[]>([]);

  const addCompanyOption = (e, { value }) => {
    const newOption = getOptionFromValue(value);
    if (newOption) setCompanyOptions([newOption, ...companyOptions]);
  };

  useEffect(() => {
    (async () => {
      const response = await api.get("/companies", { params: { for_onboarding: true } });
      const { companies } = response.data;

      setCompanyOptions(companies?.map(getOptionFromValue));
    })();
  }, []);

  return [companyOptions, addCompanyOption];
}

export default useCompanyOptions;
