import { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { Popup, Icon } from "semantic-ui-react";
import { COLORS, JS_FONTS } from "@constants";
import blueQuestionIcon from "@images/icons/question_mark_blue.svg";
import purpleQuestionIcon from "@images/icons/question_mark_purple.svg";

import PropTypes from "prop-types";

const styles = StyleSheet.create({
  Tooltip: {
    height: "15px",
    position: "relative",
    right: "2px",
    top: "-1px",
  },
  Tooltip_popupContents: {
    ...JS_FONTS.REGULAR_3,
    padding: "12px 16px",
  },

  Tooltip_link: {
    color: COLORS.HALO_BLUE,
    ":hover": {
      color: COLORS.HALO_BLUE,
    },
  },
});

const popupStyles = {
  borderColor: COLORS.WHITE,
  maxWidth: "280px",
  borderRadius: 0,
  padding: 0,
  zIndex: "1000000",
};

const mouseLeaveDelay = 175;

class Tooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = (isOpen) => {
    return () => {
      this.setState({ isOpen });
    };
  };

  renderLearnMoreLink() {
    const { linkUrl, linkText } = this.props;

    return (
      <a href={linkUrl} target="_blank" className={css(styles.Tooltip_link)} rel="noreferrer">
        {linkText}
      </a>
    );
  }

  timeoutToggle = (isOpen, timeout = mouseLeaveDelay) => {
    return () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.toggle(isOpen), timeout);
    };
  };

  renderTrigger = () => {
    const { customTrigger, iconColor, customTriggerStyle } = this.props;

    if (customTrigger) {
      const iconName = customTrigger === "incomplete" ? "clock outline" : "pause circle outline";
      return (
        <Icon
          name={iconName}
          className={customTriggerStyle}
          onMouseLeave={this.timeoutToggle(false)}
        />
      );
    }
    const questionIcon = iconColor === "purple" ? purpleQuestionIcon : blueQuestionIcon;

    return (
      <img
        src={questionIcon}
        className={css(styles.Tooltip)}
        onMouseLeave={this.timeoutToggle(false)}
      />
    );
  };

  render() {
    const { linkUrl, text, position } = this.props;

    return (
      <Popup
        hoverable
        className="Tooltip"
        onMouseEnter={this.timeoutToggle(true, 0)}
        trigger={this.renderTrigger()}
        position={position || "right center"}
        open={this.state.isOpen}
        onClose={this.toggle(false)}
        onOpen={this.toggle(true)}
        style={popupStyles}
      >
        <div onMouseLeave={this.timeoutToggle(false)} className={css(styles.Tooltip_popupContents)}>
          {text} {linkUrl && this.renderLearnMoreLink()}
        </div>
      </Popup>
    );
  }
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  iconColor: PropTypes.string,
  position: PropTypes.string,
};

export default Tooltip;
