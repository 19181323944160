import { ReactNode, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { IconButton } from "@components/library";
import { COLORS, STYLES } from "@constants";

export interface SlideModalBaseProps {
  isOpen: boolean;
  onClose: () => void;
  direction?: "bottom" | "right";
  hideCloseButton?: boolean;
  customCloseButton?: ReactNode;
  closeOnDimmerClick?: boolean;
  hideBackdrop?: boolean;
  children?: ReactNode;
}

const SlideModalBase = ({
  isOpen,
  onClose,
  direction = "right",
  hideCloseButton = false,
  customCloseButton,
  closeOnDimmerClick = true,
  hideBackdrop = false,
  children,
}: SlideModalBaseProps) => {
  // Used for the slide in/out animation
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const handleClose = () => {
    setShouldShowModal(false);
    setTimeout(() => onClose(), 300);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.code === "Escape" && isOpen) {
      if (closeOnDimmerClick) {
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setShouldShowModal(true), 0);

      document.querySelector("html")!.style.overflow = "hidden";
    }

    // Close on Escape
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.querySelector("html")!.style.overflow = "unset";

      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isOpen]);

  const modal = (
    <>
      {!hideBackdrop && <Background onClick={closeOnDimmerClick ? handleClose : null} />}
      <Modal
        aria-modal
        tabIndex={-1}
        role="dialog"
        isOpen={shouldShowModal}
        direction={direction}
        hideBackdrop={hideBackdrop}
      >
        {!hideCloseButton && (
          <CloseButton>
            {customCloseButton ?? (
              <IconButton
                iconName="Close"
                size="sm"
                variant="fourth"
                onClick={handleClose}
                tooltipPosition="bottom"
              />
            )}
          </CloseButton>
        )}
        <Content>{children}</Content>
      </Modal>
    </>
  );

  return isOpen ? ReactDOM.createPortal(modal, document.body) : null;
};

export default SlideModalBase;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${COLORS.BLACK};
  opacity: 0.7;
  z-index: 400;
`;
const Modal = styled.div`
  position: fixed;
  ${({ direction }) =>
    direction === "right"
      ? css`
          top: 0;
          right: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
          height: 100vh;
          border-radius: 12px 0px 0px 12px;
        `
      : css`
          left: 0;
          bottom: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
          width: 100vw;
          max-height: calc(100vh - 54px);
          border-radius: 12px 12px 0px 0px;
        `};
  z-index: 401;
  background: white;
  ${({ hideBackdrop }) => hideBackdrop && `box-shadow: ${STYLES.SHADOW_D}`};
  margin: auto;
  transition: ${({ direction }) => direction} 0.25s ease-out;
  max-width: 100%;
`;
const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;
const Content = styled.div`
  height: 100%;
`;
