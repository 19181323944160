import { FEATURE_FLAGS } from "@constants";
import { authenticityToken } from "@utils/requests";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": authenticityToken()!,
  },
});

// JWT refresh interceptor
api.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (
      error.response.data.error === "jwt expired" ||
      error.response.data.error === "No authorization token was found"
    ) {
      // referesh the token
      const tokenResponse = await api.get("/token");

      // token returned successfully
      if (tokenResponse?.status === 200) {
        // retry the last request
        return api.request(error.config);
      }
    } else if (error.response.data.error === "logged out") {
      window.location.href = "/login";
      return Promise.reject(error);
    }

    // Return error if none of these conditions apply
    return Promise.reject(error);
  }
);

// Redirect to reauthenticate.
if (FEATURE_FLAGS.SESSION_TIMEOUT) {
  api.interceptors.response.use(
    (res) => res,
    (error: AxiosError) => {
      if (error.response?.status !== 401) {
        return;
      }
      toast.error("You have been logged out. Please login again.");
      window.location.href = "/login";
    }
  );
}

export default api;
