const OTHER_SUBMISSION_BULLETS = [
  "Your proposal is sent to the sponsor",
  "Sponsor reviews your proposal and profile",
  "Sponsor follows up with next steps or feedback",
];

const CORTEVA = {
  UNIVERSITY_SUBMISSION_BULLETS: OTHER_SUBMISSION_BULLETS,
  STARTUP_SUBMISSION_BULLETS: OTHER_SUBMISSION_BULLETS,
  COMPOUND_EXAMPLES: [
    "Spirocyclic compounds synthesized through novel methodology",
    "Heterocycles with novel substitution that is unprecedented or difficult to access",
    "Building block is a bioisostereo for phenyl motif",
  ],
  DESIGN_PRINCIPLE_EXAMPLES: [
    "The compounds have been produced using ACS green chemistry principles.",
    "The compounds have been synthesized using from nature's chiral pool",
    "Unique building blocks with high sp3 character.",
  ],
  RATIONALE_EXAMPLES: [
    "Methodologies that follow the green chemistry principles can be potentially scaled up in a sustainable fashion.",
    "Utilizing nature's chiral pool potentially removes the need for enantiomer separation or enantioselective methods.",
    "Utilizing building blocks with high sp3 character will potentially lead to more sustainable and potent agrochemicals.",
  ],
};

const TESTING4AG = {
  UNIVERSITY_SUBMISSION_BULLETS: [
    "You will receive two emails - one from Halo and one from Docusign with the MTA",
    "Forward the email from Halo to your tech transfer contact that handles MTAs",
    "Click “Review Document” in the Docusign email and assign the MTA to the same contact",
  ],
  STARTUP_SUBMISSION_BULLETS: [
    "You will receive two emails - one from Halo and one from Docusign with the MTA",
    "Click “Review Document” in the Docusign email and sign the MTA",
    "Once the MTA is signed, Bayer will contact you to upload your compound to an FTP site",
  ],
  COMPOUND_EXAMPLES: [
    "Oxazole derivatives designed to modulate ion channels",
    "Spirocyclic compounds synthesized through novel cyclization approach",
    "Benzamides with potential herbicidal activity",
  ],
  DESIGN_PRINCIPLE_EXAMPLES: [
    "The compounds have been designed to interfere with lipid biosynthesis as inhibitors of the Acetyl-CoA-Carboxylase.",
    "",
    "The compounds have been produced in a chemistry driven synthesis approach.",
  ],
  RATIONALE_EXAMPLES: [
    "4-pyridine amides are known insecticidal compounds acting on sucking and piercing insects. Thus, closely related pyrimidine analogues should exhibit similar biological efficacy.",
    "",
    "The employed carbocyclization strategies give rise to novel molecular scaffolds that are unprecedented in commercialized active ingredients",
  ],
};

export { TESTING4AG, CORTEVA };
