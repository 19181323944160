import styled from "styled-components";
import { WINDOW_DIMENSIONS } from "@constants";

export default styled.div`
  display: flex;
  flex-direction: row;
  gap: 19px;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    flex-direction: column;
    gap: 16px;
  }
`;
