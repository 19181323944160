import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";
import { v4 as uuidv4 } from "uuid";

interface Props {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  marginTop?: string | 0;
}

const getPageButtonNumbers = (currentPage: number, totalPages: number): (number | string)[] => {
  const pageNumbers: (number | string)[] = Array.from({ length: totalPages }, (_, i) => i + 1);

  pageNumbers.filter((pageNumber) => pageNumber > 0 && pageNumber <= totalPages);

  if (currentPage + 4 < totalPages) {
    pageNumbers.splice(currentPage + 2, totalPages - (currentPage + 3), "...");
  }

  // This must come after the previous block
  if (currentPage > 5) {
    pageNumbers.splice(1, currentPage - 4, "...");
  }

  return pageNumbers;
};

const Pagination = ({ currentPage, setCurrentPage, totalPages, marginTop = 0 }: Props) => {
  const pageNumbers = getPageButtonNumbers(currentPage, totalPages);

  return (
    <Container marginTop={marginTop}>
      {currentPage > 5 && (
        <Button onClick={() => setCurrentPage(1)}>
          <Icon name="Chevron Left" color={COLORS.HALO_BLUE} />
        </Button>
      )}
      {pageNumbers.map((pageNumber) => (
        <Button
          key={pageNumber === "..." ? uuidv4() : pageNumber}
          isCurrent={currentPage === pageNumber}
          onClick={() => typeof pageNumber === "number" && setCurrentPage(pageNumber)}
        >
          {pageNumber}
        </Button>
      ))}
      {totalPages > currentPage + 4 && (
        <Button onClick={() => setCurrentPage(totalPages)}>
          <Icon name="Chevron Right" color={COLORS.HALO_BLUE} />
        </Button>
      )}
    </Container>
  );
};

export default Pagination;

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  margin-top: ${({ marginTop }) => marginTop};
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  border: none;
  background-color: ${({ isCurrent }) => (isCurrent ? COLORS.BLUE_LIGHT_300 : "transparent")};
  color: ${COLORS.HALO_BLUE};
  pointer-events: ${({ isCurrent, children }) =>
    isCurrent || children === "..." ? "none" : "auto"};
  ${({ isCurrent }) => (isCurrent ? FONTS.BOLD_2 : FONTS.MEDIUM_2)};
  &:active {
    background-color: ${COLORS.BLUE_LIGHT_400};
  }
`;
