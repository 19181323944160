import { ReactNode } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "@constants";
import { Icon } from "@components/library";

interface Props {
  shouldDisplay?: boolean;
  text: ReactNode;
  type?: "info" | "attention-dark" | "attention-orange";
  width?: string;
  margin?: string;
  padding?: string;
  "data-testid"?: string;
}

const InfoBanner = ({
  shouldDisplay = true,
  text,
  type = "info",
  width = "fit-content",
  margin,
  padding = "16px",
  "data-testid": dataTestId,
}: Props) => {
  let icon = <Icon name="Info Small" color={COLORS.BLACK} size="sm" margin="0 8px 0 0" />;
  if (type.includes("attention")) {
    icon = (
      <Icon
        name="Attention"
        color={type === "attention-dark" ? COLORS.BLACK : COLORS.ORANGE_900}
        margin="0 8px 0 0"
      />
    );
  }

  return shouldDisplay ? (
    <Container width={width} margin={margin} padding={padding} type={type} data-testid={dataTestId}>
      {icon}
      {text}
    </Container>
  ) : null;
};

export default InfoBanner;

const Container = styled.div`
  display: flex;
  width: ${({ width }) => width};
  ${({ margin }) => margin && `margin: ${margin}`};
  padding: ${({ padding }) => padding};
  border-radius: 6px;
  background-color: ${({ type }) => (type === "info" ? COLORS.NEUTRAL_100 : COLORS.ORANGE_100)};
  ${({ type }) => (type === "attention-orange" ? FONTS.MEDIUM_2 : FONTS.REGULAR_3)};
  color: ${({ type }) => (type === "attention-orange" ? COLORS.ORANGE_900 : COLORS.BLACK)};
`;
