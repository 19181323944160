import appsignal from "@utils/appsignal";
import request from "./request";

export const getDisciplines = async () => {
  const response = await request.get("/disciplines");
  return response.data;
};

export const getAreasOfExpertise = async () => {
  const response = await request.get("/areas_of_expertise");
  return response.data;
};

export const getTechniques = async () => {
  const response = await request.get("/techniques");
  return response.data;
};

export const getKeywords = async (): Promise<{
  disciplines: string[];
  areas_of_expertise: string[];
  techniques: string[];
}> => {
  const response = await request.get("/keywords");
  return response.data;
};

export const createKeyword = async (
  name: string,
  type: "Discipline" | "AreaOfExpertise" | "Technique"
): Promise<void> => {
  try {
    await request.post("/keywords", { name, type });
  } catch (err) {
    appsignal.sendError(err, (span) => {
      span.setAction("keywords#create");
      span.setTags({
        name,
      });
    });
  }
};

export const getKeywordSuggestions = async (
  keywords: string[],
  type: "All" | "Discipline" | "AreaOfExpertise" | "Technique"
): Promise<string[]> => {
  try {
    const response = await request.post("/keywords/suggestions", { keywords, type });
    return response.data.suggestions;
  } catch (err) {
    appsignal.sendError(err, (span) => {
      span.setAction("keywords#getKeywordsSuggestion");
      span.setTags({
        keywords: keywords.join(";"),
      });
    });
    return [];
  }
};
