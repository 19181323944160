export default {
  HEADING_1_SEMIBOLD: `
    font-weight: 600;
    font-size: 38px;
    line-height: 1.24;
  `,
  HEADING_2_SEMIBOLD: `
    font-weight: 600;
    font-size: 32px;
    line-height: 1.24;
  `,
  HEADING_3_SEMIBOLD: `
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  `,
  HEADING_3_REGULAR: `
  font-weight: 400;
  font-size: 26px;
  line-height: 1.6;
`,
  HEADING_4_SEMIBOLD: `
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  `,
  HEADING_4_REGULAR: `
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
  `,
  HEADING_5_MEDIUM: `
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  `,
  HEADING_5_SEMIBOLD: `
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  `,
  HEADING_5_REGULAR: `
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
`,
  HEADING_JUMBO: `
    font-weight: 700;
    font-size: 46px;
    line-height: 57px;
  `,
  REGULAR_1: `
    font-weight: 325;
    font-size: 16px;
    line-height: 1.7;
  `,
  REGULAR_2: `
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
  `,
  REGULAR_3: `
    font-weight: 400;
    font-size: 12px;
    line-height: 1.7;
  `,
  REGULAR_4: `
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  `,
  MEDIUM_1: `
    font-weight: 500;
    font-size: 16px;
    line-height: 1.7;
  `,
  MEDIUM_2: `
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;
  `,
  MEDIUM_3: `
    font-weight: 500;
    font-size: 12px;
    line-height: 1.7;
  `,
  SEMIBOLD_1: `
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  `,
  SEMIBOLD_2: `
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
  `,
  SEMIBOLD_3: `
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  `,
  SEMIBOLD_4: `
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  `,
  BOLD_2: `
    font-weight: 700;
    font-size: 14px;
    line-height: 1.7;
  `,
  BOLD_3: `
    font-weight: 700;
    font-size: 12px;
    line-height: 1.7;
  `,
  BUTTON_1: `
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
  `,
  BUTTON_2: `
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
  `,
  BUTTON_3: `
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  `,
  BUTTON_4: `
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  `,
  TAG_MEDIUM_1: `
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  `,
  TAG_MEDIUM_2: `
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  `,
  TAG_MEDIUM_3: `
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  `,
  TAG_SEMIBOLD_1: `
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  `,
  TAG_BOLD_2: `
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  `,
  TAG_BOLD_3: `
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  `,
  TAG_REGULAR_1: `
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  `,
  TAG_ACCENT: `
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
  `,
};

interface Font {
  fontWeight: 400 | 500 | 600 | 700;
  fontSize: number;
  lineHeight: number | string;
}

// Necessary while we still use Aphrodite, but eventually should be phased out
export const JS_FONTS: Record<string, Font> = {
  HEADING_1_SEMIBOLD: {
    fontWeight: 600,
    fontSize: 38,
    lineHeight: 1.24,
  },
  HEADING_2_SEMIBOLD: {
    fontWeight: 600,
    fontSize: 32,
    lineHeight: 1.24,
  },
  HEADING_3_SEMIBOLD: {
    fontWeight: 600,
    fontSize: 26,
    lineHeight: "32px",
  },
  HEADING_4_SEMIBOLD: {
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "28px",
  },
  HEADING_5_SEMIBOLD: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1.4,
  },
  REGULAR_1: {
    // @ts-ignore This weight is specific to this font style.  Adding it to the type values causes
    // a linting error everywhere else that a font type is used
    fontWeight: 325,
    fontSize: 16,
    lineHeight: 1.7,
  },
  REGULAR_2: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.7,
  },
  REGULAR_3: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.7,
  },
  REGULAR_4: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "18px",
  },
  MEDIUM_1: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.7,
  },
  MEDIUM_2: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.7,
  },
  MEDIUM_3: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.7,
  },
  SEMIBOLD_1: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.5,
  },
  SEMIBOLD_2: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
  },
  BOLD_3: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.7,
  },
};
