import { useEffect, useState } from "react";
import { convertToSnakeCase } from "@utils/textUtils";
import api from "@requests/request";

function useUniversityOptions() {
  const [universitiesOptions, setUniversitiesOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const responseJson = await api.get("/universities");

      const universities = (responseJson?.data?.universities ?? []).map((text) => ({
        key: convertToSnakeCase(text),
        text: text,
        value: text,
        label: text,
      }));

      setUniversitiesOptions(universities);
    })();
  }, []);

  return universitiesOptions;
}

export default useUniversityOptions;
